import { AvailableExceptionsEnum } from "@relay/availableExceptions_Query.graphql";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "./tree";

const AUTH_ERROR_MESSAGES: Partial<Record<AvailableExceptionsEnum, string>> = {
	auth_login_with_email_and_password_unspecified_auth: "Login fehlgeschlagen!",
	auth_user_needs_to_be_in_at_least_one_account:
		"Der Benutzer muss mindestens einem Konto zugehören.",
	auth_root_account_can_not_be_deleted: "Root Konto kann nicht gelöscht werden.",
	auth_cant_delete_only_owner_exception: "Es muss mindestens ein Admin im Konto existieren.",
	auth_user_not_activated: "Der Benutzer ist noch nicht aktiviert.",
	auth_user_already_activated: "Der Benutzer hat seine E-Mail bereits aktiviert.",
	auth_email_invalid: "Email ist ungültig oder existiert nicht.",
	auth_already_registered: "Nutzer mit der E-mail existiert bereits.",
};

const TREE_ERRORS: Record<string, string> = {
	publishingv2_tree_for_publishing_does_not_contain_content_nodes_with_elements: `Keine Elemente im ${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)}.`,
	tree_cant_remove_first_published_date_for_published_root_node: `Veröffentlichungszeitpunkt kann nicht gelöscht werden, da ${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} bereits veröffentlicht ist.`,
	tree_published_tree_parts_can_not_be_modified: `Bereits veröffentlichte Teile des ${TREE_I18N_MAP(
		TREE_I18N_KEY.treeAttributiveGenitive,
	)} können nicht verändert werden.`,
	tree_node_not_contained: "Ein Modul ist nicht Teil des Kurses",
};

const COACHING_ERRORS: Record<string, string> = {
	coaching_already_a_participant: "Benutzer ist bereits ein Teilnehmer",
};

export const LOGOUT_ERRORS: Record<string, string> = {
	auth_refresh_token_expired: "Du wurdest ausgeloggt",
	auth_jwt_malformed: "Du wurdest ausgeloggt",
};

export const AVGS_ERRORS: Record<string, string> = {
	avgspotentialanalysis_client_already_registered:
		"Der Benutzer existiert bereits und kann nicht zu einer Potential-Analyse hinzugefügt werden.",
	avgspotentialanalysis_not_enough_licenses_available:
		"Es sind nicht genügend Lizenz für die Anzahl an gewählten Modulen verfügbar.",
	avgspotentialanalysis_no_modules_selected: "Bitte wähle mindestens eine Modul aus.",
	avgspotentialanalysis_user_cannot_have_more_then_one_active_potential_analysis:
		"Der Benutzer hat noch eine offene Potential-Analyse.",
};

export const VIMEO_ERRORS: Record<string, string> = {
	vimeo_vimeo_id_not_found: "Die Vimeo-ID ist nicht korrekt.",
	vimeo_vimeo_invalid_source: "Die Vimeo-Quelle ist nicht korrekt.",
	vimeo_vimeo_server_error: "Vimeo Server Error.",
};

export const ERROR_MESSAGES: Record<string, string> = {
	...LOGOUT_ERRORS,
	...AUTH_ERROR_MESSAGES,
	...TREE_ERRORS,
	...COACHING_ERRORS,
	...AVGS_ERRORS,
	...VIMEO_ERRORS,
	certificates_certificate_template_not_found: "Kein Zertifikat gefunden.",
	file_already_exists: "Die Datei existiert bereits!",
};
