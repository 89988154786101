/**
 * @generated SignedSource<<6a56111592bb86f5c9d4a903ae1077c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectAccountField_AccountOptionsFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentType": "selectAccountField_AccountOptionsFragment";
}>;
export type selectAccountField_AccountOptionsFragment$key = ReadonlyArray<{
  readonly " $data"?: selectAccountField_AccountOptionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectAccountField_AccountOptionsFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "selectAccountField_AccountOptionsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "e92393df56d64f82c811ab9ebc22c5bd";

export default node;
